.container_principal {
  padding: 10px 15px;

  .paragrahp_download {
    position: absolute;
    gap: 5px;
    right: 50px;
    margin-top: -23px;
    width: max-content;
    padding: 0;
    font-size: 14px;
    z-index: 50;
    color: var(--light-gray-color);
    z-index: 200;

    .div {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 5px;
      padding: 0;
      cursor: pointer;

      &:active {
        opacity: 0.5;
      }
    }
  }

  .container_logo {
    width: 100%;
    padding-right: 75%;
  }

  .title_recomendation {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    background-color: var(--primary_color);
    color: #fff;
    padding-block: 10px;
  }

  .container_box_icon_description {
    padding-block: 15px;
  }

  section {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    margin-block: 5px;

    .paragrahp {
      color: var(--light-gray-color);
    }
  }

  .section_paragraph {
    display: block;
    padding-block: 10px;
    margin: 0;
    padding: 10px;
    font-size: 10px;
    border-top: 1px solid var(--light-gray-color);

    p {
      width: 100%;
      font-size: 16px;
      color: var(--light-gray-color);
      border: none;
      padding: 0;
      margin: 0;
      outline: none;
    }

    .color_paragraph {
      border: 1px solid rgba(47, 0, 132, 1);
      border-radius: 5px;
      color: var(--primary_color);
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      padding: 10px 15px;
    }

    a {
      color: var(--secondary_color);
      font-weight: 600;
      font-size: 16px;
    }
  }

  .section_btn {
    display: flex;
    align-items: center;
    width: 100%;
    margin-block: 10px;
    padding-inline: 15%;
    display: flex;
    justify-content: space-between;
  }
}
