/* Menu */

.container_menu {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #fff;
  padding-block: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
  font-size: 16px;
  z-index: 10;

  ul {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 20px;

    li {
      list-style-type: none;
      cursor: default;
    }

    li:active {
      opacity: 0.5;
    }

    .li_active {
      color: var(--primary_color);
      font-weight: 700;
    }

    .button {
      width: max-content;
      padding: 3px 7px;
      height: 35px;
    }

    .button:active {
      color: white;
    }

    .link:hover {
      background-color: transparent;
    }
  }
}

.margin_sections {
  height: 100px;
}

.container_about {
  position: relative;
  padding-top: 10px;
  width: 100%;

  .container_content_about {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 2%;
    z-index: 2;
    background-image: url("../../../assets/backgruod_about.webp");
    background-size: cover;

    .title {
      text-align: center;
      color: var(--secondary_color);
      margin-bottom: 15px;
      font-size: 28px;
      line-height: 35px;

      strong {
        color: var(--primary_color);
      }
    }

    img {
      max-width: 800px;
    }

    .link_to {
      width: 120px;
    }

    .button_start {
      padding: 5px;
      color: var(--primary_color);
    }
  }
}

.container_services {
  position: static;
  background-image: url("../../../assets/backgroud_points.webp");
  background-size: cover;

  section {
    display: flex;
    justify-content: center;
    padding-block: 2%;
    gap: 30px;

    img {
      max-width: 500px;
    }

    .card {
      padding: 20px 30px;
      width: 40%;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);

      .container_paragraph {
        color: var(--paragraph_color);
        span {
          color: var(--primary_color);
        }
        p {
          margin: 0;
        }
      }

      .container_paragraph_green {
        text-align: end;
        strong {
          margin-left: 30%;
          color: var(--secondary_color);
        }

        p {
          margin: 0;
          margin-top: 10px;
          color: var(--paragraph_color);
        }
      }
    }
  }
}

.container_demo {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../assets/background_demo.webp");
  background-size: cover;
  padding-block: 3%;

  .title_demo {
    text-align: center;
    color: var(--input-backgrond-color);
    font-size: 28px;
  }

  .paragraph_demo {
    text-align: center;
    width: 60%;
    color: var(--input-backgrond-color);
    font-weight: 300;
    margin-block: 10px;
  }

  img {
    max-width: 600px;
    margin-bottom: 10px;
  }

  .button_demo {
    color: var(--primary_color);
    width: max-content;
    padding: 5px 15px;
    border-radius: 5px;
  }
}

.container_demo_start_generated {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../assets/background_demo.webp");
  background-size: cover;
  padding-block: 3%;

  .section_input {
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 3px 19px 0px rgba(0, 0, 0, 0.4);
    padding: 20px;
    border-radius: 10px;
    min-width: 40%;
    max-width: 700px;
    margin-top: 2%;
    margin-left: 2%;

    .container_demo_input {
      position: relative;
      width: 90%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--placeholder-color);
      padding-bottom: 2px;
      margin-top: 6%;
      cursor: default;

      p {
        padding: 0;
        margin: 0;
        width: 95%;
        font-size: 16px;
        color: var(--placeholder-color);
        font-weight: 300;
      }

      .icon {
        position: absolute;
        color: var(--secondary_color);
        right: 0;
        margin-bottom: 7px;
      }

      input {
        position: absolute;
        width: 100%;
        z-index: 3;
        opacity: 0;
      }
    }

    .container_demo_input:active {
      opacity: 0.5;
    }

    .paragraph_demo_generated {
      color: var(--light-gray-color);
      font-weight: 300;
    }
  }

  .section_play_msg {
    display: flex;
    width: 90%;
    gap: 10px;

    img {
      height: 30px;
    }

    .paragraph {
      color: var(--primary_color);
      font-weight: 300;
    }
  }

  .section_buttons {
    display: flex;
    gap: 40px;
  }

  button {
    width: max-content;
    padding: 5px 20px;
    color: var(--primary_color);
    border-radius: 5px;
    margin-top: 3%;
  }

  .section_recapcha {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 15px;

    .recapcha {
      margin-left: 20%;
      transform: scale(0.9); /* Escalar el tamaño */
      -webkit-transform: scale(0.7);
      transform-origin: 0 0;
    }
  }
}

.container_modal_demo {
  display: flex;
  flex-direction: column;
  align-items: center;

  .paragraph {
    width: 95%;
    margin-top: 1%;
    text-align: center;
    color: var(--light-gray-color);
    font-weight: 300;
  }

  .button {
    margin-top: 3%;
    padding: 5px 20px;
    border-radius: 5px;
    width: max-content;
  }
}

.container_characteristics {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-block: 30px;
  background-color: var(--primary_color);

  .title {
    margin-bottom: 2%;
    text-align: center;
    color: var(--input-backgrond-color);
    width: 35%;
    font-size: 28px;
    line-height: 35px;
  }

  .container_cards_characteristics {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 20px;
    padding-inline: 10%;

    section {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 15px;
      background-color: #fff;
      border-radius: 10px;
      padding: 20px 30px;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);

      img {
        width: 25%;
      }

      .card {
        width: 100%;

        .container_paragraph {
          color: var(--paragraph_color);
          strong {
            color: var(--primary_color);
          }
          p {
            margin: 0;
          }
        }
      }
    }
  }
}

.container_plans {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-block: 30px;
  background-color: var(--input-backgrond-color);

  .title {
    margin-bottom: 2%;
    text-align: center;
    width: 35%;
    font-size: 28px;
    line-height: 35px;
  }
}

.container_contact {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-block: 30px;
  background-color: #eae5f3a8;

  .title {
    margin-bottom: 2%;
    text-align: center;
    width: 35%;
    font-size: 28px;
    line-height: 35px;
  }

  section {
    margin-top: 2%;
    width: 100%;
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 50px;
    padding-inline: 10%;

    form {
      .container_form_50 {
        margin-top: 10px;
        display: flex;
        gap: 5px;
      }

      input {
        width: 100%;
        margin-bottom: 10px;
        height: 50px;
      }

      textarea {
        width: 100%;
        min-height: 100px;
        padding: 10px;
      }
    }

    .container_icon_text {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      gap: 15px;
      .box_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--secondary_color);
        height: 50px;
        width: 50px;
        border-radius: 3px;

        .icon {
          color: var(--primary_color);
        }
      }

      .paragrap_contant {
        max-width: 80%;
        line-height: 20px;
        color: black;
        font-weight: 500;
      }
    }
  }
}

/* Footer */

.container_principal_footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  background: var(--primary_color);
  color: #fff;
}

.section_logos {
  display: flex;
  display: flex;
  padding-block: 10px;
  gap: 50px;

  a:hover {
    background-color: transparent;
  }

  a:active {
    opacity: 0.5;
  }
}

.container_logos_creatic_teampulsa {
  display: flex;
  align-items: center;
  justify-content: space-around;
  column-gap: 20px;
}
.container_logos_creatic_teampulsa a:nth-child(2) img {
  margin-top: 5px;
  width: 54px;
  height: 37px;
}
.container_icon_sn_home {
  display: flex;
  align-items: center;
  gap: 15px;

  p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #fff;
    margin: 0;
  }
}

.container_copy_rigth {
  width: 100%;
  text-align: center;
  padding-block: 10px;
  background-color: #1f0255;
  p {
    width: 100%;
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
}
