.new_password_container{
    max-width: 380px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    h2{
        font-size: clamp(1rem, 0.8333rem + 0.7407vw, 1.5rem);
        color: var(--primary_color);
        text-align: center;
        margin: 0;
    }
    img {
        width: 187px;
        height: 187px;
        margin: 0 auto;
    }

    label {
        max-width: 380px;
        font-size: clamp(0.8125rem, 0.75rem + 0.2778vw, 1rem);
        font-weight: 300;
        text-align: center;
        color: var(--color-paragraph);
        margin: 0;
    }
    input{
        width: 100%;
        margin: 2px 0;
    }
    button{
        margin: 5px 0 0 0;
    }
}