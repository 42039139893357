.container_principal_modal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container_principal_childen_modal {
  position: relative;
  width: 100%;
  background-color: #fff;
  position: fixed;
  border-radius: 1px;
  overflow-y: auto;
  padding-right: 0.2vw;
  border: none;
  outline: none;
  max-height: 90%;
  padding: 20px;
  max-height: 90%;
  max-width: 90%;
}

.container_close_modal {
  position: absolute;
  width: max-content;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  right: 5px;
  top: 5px;
}

.icon {
  font-size: 30px;
  font-weight: 900;
  color: var(--light-gray-color);
}

.icon:active {
  opacity: 0.5;
}
