.container_principal_body_knowledge {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;

  .container_not_information {
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .container_selector_description {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 2;
    gap: 15px;

    /* Accordion */

    .container_accordions {
      display: flex;
      flex-direction: column;
      gap: 1px;
      width: 50%;
      padding: 10px 10px 10px 0px;
      background-color: var(--input-backgrond-color);
      max-height: 70vh;
      overflow-y: auto;
    }

    .container_children_accordion {
      padding: 10px 20px;
      border-right: 1px solid var(--placeholder-color);
      border-bottom: 1px solid var(--placeholder-color);
      cursor: default;

      &:hover {
        background-color: #fff;
      }

      .icon {
        color: var(--light-gray-color);
      }

      .paragrap_calendar {
        display: flex;
        align-items: flex-end;
        gap: 5px;
        margin-block: 10px;

        div {
          border-right: 2px solid var(--primary_color);
          padding-right: 5px;

          strong {
            color: var(--primary_color);
          }
        }

        strong {
          color: var(--without_starting_color);
        }
      }

      .paragrap_titles {
        display: flex;
        gap: 3px;
      }

      .container_calendar_description_reports {
        display: flex;
        gap: 10px;
        margin-top: 5px;

        p {
          padding: 0;
          margin-block: 1px;
        }

        strong {
          color: var(--secondary_color);
          font-weight: 600;
        }
      }
    }

    .container_not_results {
      padding: 10px;
    }

    /* Description */

    .container_dashed {
      height: 1px;
      border-bottom: 1px dashed var(--placeholder-color);
      margin-block: 5px;
    }

    .container_description_accordion {
      margin-left: 10px;
      background-color: #fff;
      padding: 15px 20px;
    }

    .container_details_accordion {
      .paragrah_container {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-block: 10px;
      }

      span {
        color: var(--paragraph_color);
        font-weight: 400;
        margin-left: 3px;
      }

      .paragrah_containe_start {
        align-items: flex-start;

        .box_dates_status {
          display: flex;
          justify-content: space-between;
          gap: 15px;

          .span_status {
            color: var(--secondary_color);
            font-weight: 500;
          }

          .span_priority {
            color: var(--red-color);
            font-weight: 500;
          }
        }
      }

      .list_collaborator {
        display: flex;
        align-items: center;
        gap: 3px;
        margin-block: 3px;

        .icon_checkmark {
          color: var(--secondary_color);
        }

        .icon_close {
          color: var(--red-color);
        }

        .icon_elipce {
          color: var(--placeholder-color);
          margin-left: 20px;
        }

        .text_list_colaborator {
          width: 95%;
        }
      }

      .container_buttons {
        display: flex;
        width: 100%;
        justify-content: space-around;
        margin-top: 20px;

        button {
          width: max-content;
          height: 40px;
          border-radius: 5px;
          font-weight: 600;
        }
      }

      .button_gantt {
        width: max-content;
        height: 40px;
        border-radius: 5px;
        margin-top: 20px;
        font-weight: 600;
      }
    }
  }
}
