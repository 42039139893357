.container_principal {
  display: flex;
  flex-direction: column;
  align-items: center;

  .button_create {
    border: none;
    background-color: transparent;
    outline: none;

    &:active {
      opacity: 0.5;
    }
  }

  .title {
    display: flex;
    align-items: center;
    gap: 1px;
    cursor: default;
    font-weight: 600;

    .icon {
      color: var(--secondary_color);
    }
  }

  .title:active {
    opacity: 0.5;
  }

  .calendar_container {
    width: 400px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    border-radius: 10px;
    font-weight: 300;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    color: var(--light-gray-color);
    padding: 10px 15px;
  }

  .calendar_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 30px;
    font-size: 25px;

    .icon_header {
      color: var(--light-gray-color);
    }

    div {
      margin-top: -5px;
      height: 100%;
    }
  }

  .calendar_header button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }

  .calendar_grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 5px;
    padding: 5px;
  }

  .calendar_day_header {
    font-weight: 500;
    text-align: center;
    color: var(--paragraph_color);
  }

  .calendar_day {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 18px;
    border-radius: 100%;
    transition: background-color 0.3s;
    color: #606263;
  }

  .calendar_day.empty {
    background-color: #f9f9f9;
    cursor: default;
  }

  .calendar_day:hover {
    background-color: #eee;
  }

  .calendar_day.selected {
    background-color: var(--primary_color);
    color: #fff;
    border-radius: 100%;
  }
}
