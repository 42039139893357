.container_principal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .container_bar_percentage {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    background-color: var(--text-area-color);
    width: 70%;

    .progresive_box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      text-align: center;
      background-color: var(--secondary_color);

      .span {
        width: 100%;
        text-align: center;
      }
    }
  }

  .container_legend {
    position: absolute;
    bottom: 15px;
    .box {
      height: 15px;
      width: 15px;
      background-color: var(--secondary_color);
    }
  }
}
