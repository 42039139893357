.user_container {
  max-width: 1200px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  z-index: 2;

  .title_table {
    margin-top: 15px;
  }

  .controls {
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
  }

  .toggle {
    width: 180px;
    background-color: red;
  }

  table {
    width: 100%;
    text-align: center;
    border-spacing: 0px 7px;
    padding: 0 10%;
    table-layout: fixed;

    thead {
      tr {
        font-size: 16px;
        font-weight: 500;
        color: white;
        background-color: var(--primary_color);

        th {
          padding: 14px 0;
          width: 30%;
        }
      }
    }

    tbody {
      tr {
        font-size: 17px;
        font-weight: 300;
        color: var(--color-paragraph);
        background: rgba(244, 244, 244, 1);

        td {
          padding: 14px 0;

          div {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 15px;

            p {
              width: 200px;
              text-align: start;
              margin: 0;
            }
          }

          .actions {
            column-gap: 0;

            span {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 9px;
              font-weight: 300;
              color: var(--primary_color);
              padding: 0 3px;

              &:nth-child(1) {
                background-color: var(--secondary_color_opacity);
              }

              &:nth-child(2) {
                background-color: rgba(204, 255, 102, 0.31);
              }

              &:nth-child(3) {
                color: #e8e7ff;
                background-color: rgba(238, 53, 55, 0.28);
              }
            }
          }
        }
      }
    }
  }
}
