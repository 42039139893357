.pagination {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  bottom: 0;
  margin: 0;
  padding-block: 1%;
  z-index: 100;
}

.page-item .page-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
  height: 25px;
  width: 25px;
  border-radius: 100%;
  text-align: center;
  color: var(--primary_color);
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
}

.page-item a.page-link:hover {
  background-color: var(--primary_color);
  color: #ffffff;
}

.page-item.active .page-link {

  background-color: var(--secondary_color);
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
}
