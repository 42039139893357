.user_container {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;

  .title_table {
    margin-top: 15px;
  }

  .controls {
    display: flex;
    align-items: center;
    padding-right: 15px;
    width: max-content;
    gap: 10px;

    .toggle {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 15px;
      width: 180px;
      height: 100%;
      font-weight: 600;

      span {
        display: flex;
        align-items: center;
        cursor: default;
      }
    }
  }

  table {
    max-width: 100%;
    text-align: center;
    border-spacing: 10px 7px;
    table-layout: fixed;
    margin-bottom: 20px;
    p {
      text-align: center;
      color: var(--primary_color);
      margin: 0 auto;
    }
    tr {
      background-color: rgba(244, 244, 244, 1);

      &:hover {
        background: rgba(234, 229, 243, 1);
      }

      th {
        border-collapse: collapse;
        border-spacing: 0;
        font-size: 14px;
        font-weight: 400;
        color: var(--primary_color);
        padding: 2px 10px;
        width: 430px;
      }

      td {
        font-size: 14px;
        font-weight: 300;
        color: var(--color-paragraph);
        padding: 15px 0px;
        text-align: center;
        padding-inline: 5px;

        div {
          display: flex;
          align-items: center;
          justify-content: center;

          .icon_td {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 20%;
          }

          p {
            text-align: start;
            width: 70%;
            margin: 0;
            padding: 0;
          }
        }

        & strong {
          color: var(--primary_color);
        }

        .icon {
          color: var(--color-paragraph);
        }
      }

      .td_description {
        text-align: left;
        overflow-y: auto;

        div {
          max-height: 60px;
        }
      }

      .td_color {
        color: var(--primary_color);
        font-weight: 500;
      }

      .th_id_level_action {
        width: 80px;
      }

      .th_level_action {
        width: 150px;
      }

      .td_icon_description {
        display: flex;
        align-items: center;
        text-align: center;
        width: 100%;
        gap: 10px;

        .disable_icon {
          pointer-events: none;
          opacity: 0.5;
        }
      }

      .td_icon_edit {
        margin-right: 15px;
      }
    }

    .active {
      background: rgba(234, 229, 243, 1);
    }
  }
}
