.container_principal {
  width: 100%;
  display: flex;
  gap: 20px;

  .container_chart {
    width: 75%;
    padding: 0;

    .y_label {
      text-align: start;
      margin: 0px 0px 3px 5px;
    }

    .x_label {
      margin-top: -20px;
    }
  }

  .container_legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: max-content;

    .title {
      text-align: start;
    }

    .container_circle_point {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;

      .box {
        width: 15px;
        height: 15px;
        border-radius: 100%;
      }

      span {
        text-align: start;
        width: 80%;
      }
    }
  }
}
