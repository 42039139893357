.container_principal {
  margin-top: -15px;

  .container_legend {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding-top: 10px;
    margin-bottom: 20px;

    .container_box_text {
      width: max-content;
      display: flex;
      align-items: center;
      gap: 5px;

      .box {
        height: 10px;
        width: 10px;
        background-color: #fff;
      }
    }
  }

  .container_chart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .text_y_axis {
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
      width: 300px;
      margin-left: -140px;
      margin-bottom: 20px;
    }

    .box_chart {
      width: 100%;
      text-align: center;
      margin-left: -150px;

      .text_x_axis {
        margin-left: 50px;
      }
    }
  }
}
