.card_sucription {
  max-width: 350px;
  padding: 38px 20px;
  box-shadow: 0px 0px 20px 0px #00000040;
  position: relative;
  border-radius: 3px;

  header {
    display: grid;
    row-gap: 20px;
    span.recommendation {
      background-color: var(--secondary_color);
      font-size: 20px;
      font-weight: 800;
      color: white;
      width: 100%;
      display: inline-block;
      text-align: center;
      padding-block: 6px;
    }
    img {
      margin: 0 auto;
    }
  }
  div.suscription_content {
    padding-bottom: 50px;
    h1 {
      font-size: 32px;
      color: var(--primary_color);
      font-weight: 600;
      text-align: center;
      margin-bottom: 0;
    }
    p {
      font-size: 28px;
      color: var(--primary_color);
      font-weight: 300;
      text-align: center;
      margin: 0;
      span {
        font-size: 20px;
      }
    }
    ul {
      list-style-type: none;
      padding: 0;
      li {
        padding-left: 30px;
        background-image: url("../../../assets/icons/check.svg");
        background-repeat: no-repeat;
        background-position: left center;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }
  footer {
    position: absolute;
    bottom: 38px;
    left: 20px;
    right: 20px;
  }
}
