header.profile {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5%;
  column-gap: 10px;
  margin-block: 5px;
  img {
    border-radius: 100%;
    object-fit: cover;
    border: 1px solid var(--placeholder-color);
  }
  
  div.content_buttons{
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
    margin-top: 1rem;
}
}
