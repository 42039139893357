.process_container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  color: var(--primary_color);

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    margin: 0;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .content_left {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;

    div {
      display: flex;
      flex-direction: column;
    }

    input {
      min-width: 350px;
      height: 57px;
      font-size: clamp(0.875rem, 0.8482rem + 0.119vw, 0.9375rem);
      border: 1px solid var(--placeholder-color);
      border-radius: 0.3rem;
      padding-inline: 10px;
      color: var(--primary_color);
      font-weight: 300;

      &:focus {
        outline-color: var(--primary_color);
      }
    }

    input::placeholder {
      color: var(--placeholder-color);
    }

    .input_number_try {
      margin-top: 18px;
    }

    label {
      width: 100%;
      font-size: 12px;
      color: rgba(96, 98, 99, 1);
      margin: 0;
      text-align: start;
      margin-left: 10px;
    }
  }
}

div.content_buttons {
  min-width: 540px;
  display: flex;
  justify-content: center;
  column-gap: 0.5rem;
  margin: 0 auto;
}
