.container_principal_modal_tree {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 500;
  max-width: 900;

  svg {
    padding: 0;
    display: flex;
    align-items: center;
    height: max-content;
    overflow-y: auto;
  }

  .section_btn {
    display: flex;
    align-items: center;
    width: 100%;
    margin-block: 10px;
    padding-inline: 15%;
    display: flex;
    justify-content: space-between;
  }
  .container_legend {
    margin-left: 70% ;
    margin-top: 10%;
    display: flex;
    position: absolute;
    margin-left: "100px";
    flex-direction: "column";
    align-items: "flex-start";
    .box {
      height: 15px;
      width: 15px;
      background-color: var(--secondary_color);
    }
  }
  
    .container_circle_point {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;
      background: white;
      border-radius: 3px;

      .box {
        width: 15px;
        height: 15px;
        border-radius: 100%;
      }

      span {
        text-align: start;
        width: 90%;
      }
    }
}
