.notification_container {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;

  .title_table {
    margin-top: 15px;
  }

  .controls {
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    column-gap: 1rem;
    align-items: center;
    padding-right: 15px;
  }

  .content {
    h2 {
      font-size: 20px;
      font-weight: 500;
      margin: 0 0 20px 105px;
      color: var(--primary_color);
    }
    table {
      max-width: 950px;
      text-align: center;
      border-spacing: 10px 7px;
      table-layout: fixed;
      margin-bottom: 20px;
      p {
        text-align: center;
        color: var(--primary_color);
        margin: 0 auto;
      }
      tr {
        background-color: rgba(244, 244, 244, 1);

        &:hover {
          background: rgba(234, 229, 243, 1);
        }

        th {
          border-collapse: collapse;
          border-spacing: 0;
          font-size: 14px;
          font-weight: 400;
          color: var(--primary_color);
          padding: 2px 10px;
          width: 350px;
        }

        td {
          font-size: 14px;
          font-weight: 300;
          color: var(--color-paragraph);
          padding: 15px 0px;
          text-align: center;
          padding-inline: 5px;

          & strong {
            color: var(--primary_color);
          }

          .icon {
            color: var(--color-paragraph);
          }
        }

        .td_description {
          text-align: left;
          overflow-y: auto;

          div {
            max-height: 60px;
          }
        }

        .td_color {
          color: var(--primary_color);
          font-weight: 500;
        }

        .th_id_level_action {
          width: 200px;
        }

        .th_level_action {
          width: 150px;
        }

        .th_description {
          min-width: 400px;
          max-width: 500px;
        }

        .td_icon_description {
          display: flex;
          align-items: center;
          text-align: left;
          width: 100%;
          gap: 10px;

          & .icon_width {
            width: 50px;
            font-size: 25px;
          }

          & span {
            width: 100%;
          }
        }

        .td_icon_edit {
          margin-right: 15px;
        }

        .td_disable_icon {
          pointer-events: none;
          opacity: 0.5;
        }
      }

      .active {
        background: rgba(234, 229, 243, 1);
      }
    }
  }
}
