.disabled {
  /* Estilos para cuando los elementos están deshabilitados */
  opacity: 0.5;
  pointer-events: none; /* Esto desactiva la interacción con los elementos */
}
.flash_text {
  animation: flash 3s infinite;
  
  @keyframes flash {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
}
.container_principal {
  position: relative;
  color: var(--primary_color);
  z-index: 2;
  margin-top: -10px;

  .toggle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 30px;
    height: 30px;
    font-weight: 600;

    span {
      display: flex;
      align-items: center;
      cursor: default;
    }
  }

  .toggle_switch {
    margin-top: 15px;
  }

  .process_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content {
      position: relative;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      margin-bottom: 20px;
      column-gap: 1rem;

      section {
        flex-direction: column;
        text-align: center;

        div {
          display: flex;
          gap: 10px;
        }
      }

      h1 {
        margin: 0;
        font-weight: 600;
        margin-bottom: 10px;
      }

      div.content_left,
      div.disabled,
      div.content_center,
      div.content_right {
        text-align: center;
        display: flex;
        flex-direction: column;

        input {
          font-size: clamp(0.875rem, 0.8482rem + 0.119vw, 0.9375rem);
          border-radius: 0.3rem;
          padding-left: 0.5rem;

          &::placeholder {
            color: var(--placeholder-color);
          }

          &:focus {
            outline-color: var(--primary_color);
          }
        }
      }

      .content_left {
        flex-direction: column;
        row-gap: 0.5rem;

        input,
        textarea,
        select {
          min-width: 290px;
        }

        ul.content_search {
          position: relative;
          margin-top: 1rem;
          padding: 0 2rem 2rem;
          background: rgba(255, 255, 255, 1);
          z-index: 3;

          li {
            font-size: 16px;
            list-style: none;
            padding: 0.5rem 0;
            border-bottom: 1px solid var(--placeholder-color);
            cursor: pointer;
            button {
              padding-bottom: 1rem;
            }
          }
        }

        .button_calendar:active {
          opacity: 0.5;
        }
      }

      .content_right {
        flex-direction: column;
        align-items: f;

        ul {
          width: 300px;
          height: 260px;
          border: 1px solid var(--placeholder-color);
          border-radius: 0.3rem;
          padding: 0.5rem;
          overflow-y: scroll;
          margin: 0;
          margin-top: 4px;
          margin-bottom: 9px;
          text-align: start;
        }

        li {
          display: flex;
          align-items: center;
          column-gap: 0.5rem;
          height: max-content;
          margin: 0.4rem 0;
          list-style: none;
          font-size: 14px;
          color: var(--gray-color);

          input[type="checkbox"] {
            accent-color: var(--primary_color);
            min-width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }
      }

      .content_center {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        ul {
          width: 300px;
          max-height: 100px;
          min-height: 105px;
          border: 1px solid var(--placeholder-color);
          border-radius: 0.3rem;
          padding: 5px 10px;
          margin: 0;
          overflow-y: scroll;

          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: max-content;
            column-gap: 0.5rem;
            margin: 0.4rem 0;
            list-style: none;
            font-size: 14px;
            color: var(--gray-color);
            border-bottom: 1px solid var(--placeholder-color);
            padding-bottom: 10px;

            input[type="checkbox"] {
              accent-color: var(--primary_color);
              min-width: 24px;
              height: 24px;
              cursor: pointer;
            }
          }
        }

        .label_calendar {
          width: 100%;
          height: 8px;
          font-size: 12px;
          color: rgba(96, 98, 99, 1);
          margin: 0;
          text-align: start;
          margin-left: 10px;
        }

        .button_calendar {
          display: flex;
          align-items: center;
          gap: 10px;
          color: var(--primary_color);
          background-color: #fff;
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          padding-inline: 10px;
          height: 57px;
          border-radius: 3px;
          border: 1px solid var(--placeholder-color);

          .icon {
            font-size: 23px;
          }
        }
      }
    }

    .container_subtask {
      flex-direction: column;
      width: 920px;
      margin-inline: 35px;
      text-align: start;
      margin-left: 30px;
      

      .container_show_sub_task {
        background-color: #fff;
        flex-direction: column;
        width: 100%;
        padding: 15px 15px;
        border-radius: 5px;
        border: 1px solid var(--placeholder-color);

        div {
          display: flex;
          width: 100%;
          li {
            width: 90%;
            margin-bottom: 3px;
            text-align: start;
            color: var(--color-paragraph);
          }

          input {
            margin-top: 3px;
            width: 90%;
            border: none;
            border-bottom: 1px solid var(--placeholder-color);
            height: max-content;
            border-radius: 0;
            color: var(--color-paragraph);

            &:focus {
              outline: none;
              border-bottom: 2px solid var(--primary_color);
            }
          }

          .container_icons {
            display: flex;
            justify-content: space-around;
            width: 10%;
            color: var(--placeholder-color);

            .icon:active {
              opacity: 0.5;
            }
          }

          input.input_add_sub_task {
            width: 60%;
          }
        }
      }
    }

    .content_buttons {
      margin-block: 15px;
      width: 100%;
      padding-inline: 20%;
      display: flex;
      justify-content: center;
      column-gap: 0.5rem;

      .button_update {
        color: var(--primary_color);
      }
    }
  }
}
