.speech_tree_container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 3rem;
  overflow-y: scroll;
  z-index: 3;
  hr {
    height: 1px;
    width: 100%;
  }
  h2 {
    font-size: 25px;
    font-weight: 800;
    margin-top: 0;
    color: var(--primary_color);
  }

  p {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    font-size: 16px;
    font-weight: 700;
    color: var(--color-paragraph);
    margin: 0.5rem 0;

    span {
      color: var(--placeholder-color);
      font-size: 16px;
      font-weight: 500;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: 600;
    color: var(--color-paragraph);
    margin: 0;
  }

  .content_collaborators {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    align-items: center;
    padding-bottom: 0.3rem;
    ul.column {
      min-width: 270px;
      height: 170px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      border: 1px solid var(--placeholder-color);
      padding: 0.5rem 1rem;
      margin: 0;
      overflow-y: scroll;
      background-color: white;
      li {
        font-size: 14px;
        font-weight: 400;
        list-style: none;
        border-bottom: 1px solid var(--placeholder-color);
        padding: 0.4rem 0;
      }
    }
  }
  .actions {
    display: flex;
    align-items: flex-end;
    column-gap: 0.5rem;
    button {
      font-size: 16px;
      font-weight: 600;
      height: 40px;
      color: var(--primary_color);
      border: none;
      border-radius: 4.5px;
      padding: 0.3rem 1rem;
      background-color: var(--secondary_color);
      cursor: pointer;
    }
    div {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      font-size: 18px;
      color: var(--color-paragraph);
      text-decoration: none;
      cursor: default;
    }

    div:active {
      opacity: 0.5;
    }
  }
  .action_step {
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    margin: 3rem 0;
    button {
      width: 30%;
    }
    a.link {
      width: 30%;
      font-size: 16px;
      text-decoration: none;
    }
  }
}
