.container_principal {
  padding-inline: 10px;

  .paragrahp_download {
    position: relative;
    gap: 5px;
    margin-top: -10px;
    width: 100%;
    padding: 0;
    padding-right: 5%;
    font-size: 14px;
    z-index: 200;
    color: var(--light-gray-color);

    .div {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 5px;
      padding: 0;
      cursor: pointer;
    }

    .div:active {
      opacity: 0.5;
    }
  }

  .container_box_icon_description {
    padding-block: 15px;
  }

  section {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    margin-block: 5px;

    .paragrahp {
      color: var(--light-gray-color);
    }
  }

  .section_paragraph {
    display: block;
    padding-block: 10px;
    margin: 0;
    padding: 10px;
    font-size: 10px;
    border-top: 1px solid var(--light-gray-color);

    textarea {
      width: 100%;
      font-size: 16px;
      color: var(--light-gray-color);
      border: none;
      padding: 0;
      margin: 0;
      outline: none;
    }
  }

  .section_btn {
    display: flex;
    align-items: center;
    width: 100%;
    margin-block: 10px;
    padding-inline: 15%;
    display: flex;
    justify-content: space-between;
  }
}
