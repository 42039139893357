.new_plan_container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  div.header {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: clamp(0.875rem, 0.7143rem + 0.7143vw, 1.25rem);
      font-weight: 800;
      color: var(--primary_color);
      margin: 0 auto 0 auto;
    }

    button {
      display: flex;
      align-items: center;
      column-gap: 0.1rem;
      font-size: 14px;
      color: var(--placeholder-color);
      border: none;
      background-color: white;
      cursor: pointer;
    }
  }

  div.content {
    display: flex;
    justify-content: space-around;
    column-gap: 0.5rem;

    div.content_left,
    div.content_right {
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    div.content_left {
      row-gap: 0.5rem;

      input,
      textarea {
        font-size: clamp(0.875rem, 0.8482rem + 0.119vw, 0.9375rem);
        border: 1px solid var(--placeholder-color);
        border-radius: 0.3rem;
        padding-left: 0.5rem;

        &::placeholder {
          color: var(--placeholder-color);
        }

        &:focus {
          outline-color: var(--primary_color);
        }
      }

      input {
        height: 57px;
      }

      textarea {
        resize: none;
        min-height: 125px;
        form-sizing: content;
        font-family: var(--font-name);
        padding: 1rem 0.5rem;
      }
    }

    div.content_right {
      button {
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        font-size: clamp(0.875rem, 0.7885rem + 0.3846vw, 1.125rem);
        font-weight: 600;
        color: var(--primary_color);
        padding-top: 0.7rem;
        border: none;
        background: none;
        cursor: pointer;
      }

      ul.content_checkbox {
        max-height: 189px;
        border: 1px solid var(--placeholder-color);
        border-radius: 0.3rem;
        padding: 0.5rem;
        overflow-y: scroll;

        li {
          display: flex;
          align-items: center;
          column-gap: 0.5rem;
          height: max-content;
          margin: 0.4rem 0;
          list-style: none;
          font-size: 14px;
          color: var(--gray-color);

          input[type="checkbox"] {
            accent-color: var(--primary_color);
            min-width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }
      }
    }
  }

  div.content_buttons {
    display: flex;
    justify-content: center;
    column-gap: 0.5rem;
  }
}
