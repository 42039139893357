.title_create_event {
  position: relative;
  margin-inline: 5%;
  color: #fff;
  background-color: var(--primary_color);
  padding: 5px 15px;
  font-size: 18px;
}

.container_add_avent {
  position: relative;
  padding: 5%;
  display: flex;
  justify-content: center;
  z-index: 10;

  form {
    width: 80%;

    input,
    textarea {
      width: 100%;
      padding: 5px 15px;
      font-size: 16px;
      margin-bottom: 10px;
      outline: none;
      color: var(--primary_color);
      border-radius: 5px;
    }

    input {
      border: none;
      border-radius: 0px;
      border-bottom: 1px solid var(--placeholder-color);
    }

    textarea {
      min-height: 100px;
    }

    .section_selects {
      display: flex;
      gap: 30px;
      width: 100%;

      .container_box_select_label {
        width: 100%;

        select {
          width: 100%;
        }
      }
    }

    .section_date_hours {
      position: relative;
      display: flex;
      gap: 30px;
      margin-top: 20px;

      .container_div_icon_text {
        position: relative;
        width: 100%;
        div {
          position: relative;
          display: flex;
          align-items: flex-end;
          gap: 5px;
          width: 100%;

          .paragraph {
            color: var(--light-gray-color);
            font-weight: 300;
          }
        }

        input {
          position: absolute;
          top: 0;
          opacity: 1;
          z-index: 5;
        }
      }
    }

    .section_input_time {
      display: flex;
      margin-top: 15px;
      width: 100%;
      justify-content: space-around;
      gap: 15px;

      div {
        width: 100%;
        input {
          width: 100%;
          height: 50px;
          border: none;
          border-radius: 5px;
          border: 1px solid var(--placeholder-color);
        }
      }
    }
  }
}

.section_buttons {
  display: flex;
  width: 100%;
  padding-inline: 20%;
  margin-top: 20px;
  gap: 10px;
  justify-content: center;
}

.container_add_person {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-left: 10%;

  .container_sections {
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 15px;

    ul {
      position: relative;
      margin-top: 10px;
      padding: 5px 15px 10px 15px;
      background: rgba(255, 255, 255, 1);
      z-index: 3;
      min-height: 50px;
      max-height: 200px;
      width: 350px;
      overflow-y: auto;
      border: 1px solid var(--placeholder-color);

      li {
        display: flex;
        align-items: center;
        font-size: 16px;
        list-style: none;
        padding: 0.5rem 0;
        border-bottom: 1px solid var(--placeholder-color);
        cursor: pointer;
        span {
          width: 45%;
          margin-right: 5%;
        }
        .span_external {
          width: 90%;
        }
        .span_review {
          width: 45%;
        }
      }
    }

    .input_autocompleted:hover {
      outline: none;
    }

    .input_add_person {
      width: 100%;
      height: 60px;
      min-width: 300px;
      border-radius: 5px;
      font-size: 18px;
    }

    .input_add_person::placeholder {
      color: var(--paragraph_color);
      font-weight: 300;
    }
  }
}
