.container {
  height: 100%;
  width: 100%;
  display: flex;
  overflow-y: auto;

  aside {
    width: 320px;
    z-index: 2;
    max-height: 100vh;
    overflow-y: auto;
  }

  main {
    position: relative;
    height: 100vh;
    overflow-y: auto;
    width: calc(100% - 300px);
    .bars {
      position: fixed;
      left: 320px;
      bottom: 0;
      z-index: 1;
      margin-left: -180px;
    }
  }
}
