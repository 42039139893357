.user_button {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  background-color: white;
  color: var(--primary_color);

  &:disabled {
    opacity: 0.5;
  }
}

.user_button:active {
  opacity: 0.5;
}
