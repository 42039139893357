.container_principal {
  position: relative;
  color: var(--primary_color);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  max-width: 600px;

  section {
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid var(--placeholder-color);
    padding: 10px 15px;
    border-radius: 3px;
    margin-bottom: 15px;
    max-height: 150px;
    overflow-y: auto;
  }

  .container_name_subtask {
    width: 100%;

    .paragraph {
      font-size: 14px;
      color: var(--light-gray-color);
      font-weight: 300;
      margin: -3px 0px 3px 0px;
    }

    .container_checkbox_task_name {
      display: flex;
      align-items: center;
      gap: 5px;

      .checkbox {
        width: 20px;
        height: 20px;
        border: 1px solid var(--placeholder-color);
        cursor: default;
      }

      .checkbox_green {
        background-color: var(--secondary_color);
      }
    }

    .container_circle_subtask_name {
      display: flex;
      width: 100%;
      align-items: flex-start;
      gap: 5px;

      .circle_box {
        margin-top: 5px;
        width: 10px;
        height: 10px;
        background-color: var(--secondary_color);
        border-radius: 100%;
      }

      .description {
        max-width: 95%;
      }
    }
  }

  .content_buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    column-gap: 0.5rem;
    margin: 0 auto;
  }
}
