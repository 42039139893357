.container_principal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .line_botton {
    position: relative;
    margin-top: -6px;
    height: 4px;
    background-color: var(--placeholder-color);
    z-index: 3;
    margin-bottom: 3px;
    width: 250px;
  }

  .container_legend {
    margin-top: 10px;
    .box {
      height: 15px;
      width: 15px;
      background-color: var(--secondary_color);
    }
  }
}
