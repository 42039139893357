.dialog_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.content_dialog,
.content_second {
  max-width: 80%;
  max-height: 90%;
  position: relative;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: white;
  overflow: auto;
}

.close_button_gray {
  position: absolute;
  top: 8px;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--light-gray-color);
}

.close_button_primary {
  display: none;
}

.content_second {
  width: 700px;
  max-width: 100%;
}
