* {
  box-sizing: border-box;
}
.filter_form {
  max-height: 67px;
  width: 590px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  box-shadow: 0px 0px 20px 0px #00000040;
  padding: 14px 60px 14px 30px;

  label {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--primary_color);
    cursor: pointer;

    input {
      border: none;
      border-left: solid 2px #f3f3f3;
      padding: 5px 10px;


      &::placeholder{
        color: var(--placeholder-color);
        font-weight: 300;
        font-size: 16px;
      }
    }

    input:focus {
      outline: none;
    }
  }
  span.pending {
    display: inline-block;
    width: 160px;
    min-height: 30px;
    background-color: red;
  }
}
