.container_login {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("../../../assets/bg-register.webp");
  background-size: cover;
}

.container_title_form {
  display: block;
  padding-left: 5%;
  padding-top: 5%;
}

.paragrap {
  margin-top: 1px;
}

/* Form */

.container_form {
  display: grid;
  margin-top: 2%;
  grid-template-rows: auto;
  width: 25%;
  padding-block: 5px;
  row-gap: 10px;
}

.text_cancel_process {
  text-align: end;
}

/* link form */

.container_link {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.button_container{
  display: flex;
  gap: 15px;
  width: 100%;
  margin-top: 80px;
}
