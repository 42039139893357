.notification_container {
  min-width: 1000px;
  max-width: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  z-index: 2;

  .controls {
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
  }

  .toggle {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    width: 180px;
    height: 100%;
    font-weight: 600;

    span {
      display: flex;
      align-items: center;
      cursor: default;
    }
  }

  h2 {
    width: 100%;
    max-width: 800px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: var(--primary_color);
    background-color: rgba(244, 244, 244, 0.7);
    margin: 2rem auto 0.5rem auto;
  }

  .content {
    width: 100%;
    max-width: 800px;
    padding: 1rem;
    margin: 0 auto;
    background-color: rgba(244, 244, 244, 0.7);

    div.head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(210, 212, 213, 1);
      padding-bottom: 1rem;

      button {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        font-size: 22px;
        font-weight: 600;
        color: var(--primary_color);
        border: none;
        background: none;
        cursor: pointer;
      }
    }

    div.plans {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(210, 212, 213, 1);
      padding: 1rem;

      h3 {
        font-size: 30px;
        font-weight: 800;
        color: var(--primary_color);
        margin: 0;
      }

      p {
        font-size: 12px;
        font-weight: 600;
        color: black;
        margin: 0;

        span {
          font-weight: 300;
          color: var(--primary_color);
        }
      }

      p.price {
        font-size: 27px;
        font-weight: 300;
        color: var(--primary_color);

        span {
          font-size: 16px;
        }
      }
      .content_buttons {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        button {
          border: none;
          background-color: transparent;
          cursor: pointer;
        }
      }
    }
  }
}
