.container_register {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/bg-register.webp");
  background-size: cover;
}

.container_title_form {
  display: block;
  padding-left: 5%;
  padding-top: 3%;
}

.paragrap {
  margin-top: 1px;
}

/* Form */

.container_form {
  display: grid;
  margin-top: 1%;
  grid-template-rows: auto;
  width: 350px;
  padding-block: 5px;
  row-gap: 10px;

  input {
    width: 100%;
  }
}

.text_cancel_process {
  text-align: end;
}
