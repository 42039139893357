.container_form {
  margin-left: -2%;
  width: 104%;
  padding-inline: 2%;
  margin-top: 10px;

  section {
    position: relative;
    z-index: 10;
    display: block;
    margin-top: 0px;

    label {
      display: block;
      font-size: 14px;
      color: var(--placeholder-color);
      font-weight: 400;
      padding-left: 20px;
      margin: 0;
    }

    input {
      width: 100%;
      background-color: var(--input-backgrond-color);
      padding: 10px 20px;
      border: none;
      outline: none;
      font-size: 16px;
      color: var(--primary_color);

      &:disabled {
        opacity: 0.5;
      }
    }

    textarea {
      width: 100%;
      background-color: var(--input-backgrond-color);
      padding: 10px 20px;
      border: none;
      outline: none;
      font-size: 16px;
      resize: vertical;
      min-height: 150px;
      color: var(--primary_color);
    }
  }

  .section_btn {
    padding-block: 20px;
    width: 90%;
    bottom: 0;
    padding-inline: 25%;
    display: flex;
    gap: 20px;
    justify-content: space-between;

    .buttons {
      padding: 5px;
      height: 40px;
    }
  }
}
