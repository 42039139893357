.payments_container {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: auto;

  .title_table {
    margin-top: 15px;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
    width: max-content;
  }

  .toggle {
    width: 180px;
  }

  .content {
    display: flex;
    justify-content: center;

    h2 {
      font-size: 20px;
      font-weight: 500;
      margin: 0 0 20px 105px;
      color: var(--primary_color);
    }
    table {
      max-width: 950px;
      text-align: center;
      border-spacing: 10px 7px;
      table-layout: fixed;
      p {
        text-align: center;
        color: var(--primary_color);
        margin: 0 auto;
      }
      tr {
        background-color: rgba(244, 244, 244, 1);

        &:hover {
          background: rgba(234, 229, 243, 1);
        }

        th {
          border-collapse: collapse;
          border-spacing: 0;
          font-size: 14px;
          font-weight: 400;
          color: var(--primary_color);
          padding: 2px 10px;
          width: 250px;
        }

        td {
          font-size: 14px;
          font-weight: 300;
          color: var(--color-paragraph);
          padding: 15px 0px;
          text-align: center;
          padding-inline: 5px;
        }

        .td_color {
          color: var(--primary_color);
          font-weight: 500;
        }

        .td_icon_description {
          display: flex;
          align-items: center;
          text-align: left;
          width: 100%;
          gap: 10px;

          & .icon_width {
            width: 50px;
            font-size: 25px;
          }

          & span {
            width: 100%;
          }
        }
      }

      .active {
        background: rgba(234, 229, 243, 1);
      }
    }
  }
}
