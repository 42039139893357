.container_principal {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: var(--primary_color);
  color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
  height: max-content;
}
