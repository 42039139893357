.container_register {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/bg-register.webp");
  background-size: cover;
}

.container_title_form {
  display: block;
  padding-left: 5%;
  padding-top: 3%;
}

.paragrap {
  margin-top: 1px;
}

/* Form */

.container_form {
  display: grid;
  margin-top: 1%;
  grid-template-rows: auto;
  width: 410px;
  padding-block: 5px;
  row-gap: 10px;

  input,
  select {
    width: 100%;
  }

  .container_term_conditions {
    display: flex;
    align-items: flex-start;
    width: 400px;

    input {
      width: 10%;
    }

    .container_text {
      font-size: 14px;
      width: 90%;
      padding: 0;
      margin-top: -3px;
      margin-bottom: 10px;

      strong {
        color: var(--secondary_color);
        cursor: default;

        &:active {
          opacity: 0.5;
        }

        &:hover {
          border-bottom: 1px solid var(--secondary_color);
        }
      }
    }
  }
}

.text_cancel_process {
  text-align: end;
}

/* Modal termines and conditions */

.container_modal_term_condition {
  padding-top: 4%;
  height: 90%;
  width: 95%;
  margin-inline: 2.5%;

  .container_buttons {
    display: flex;
    gap: 15px;
    margin-top: 2%;
    padding-inline: 20%;

    button {
      padding: 0;
      margin: 0;
      width: 100%;
      height: 35px;
    }
  }
}
