.container_principal {
  padding-inline: 5%;

  .minute_information {
    width: 100%;
    background-color: var(--input-backgrond-color);
    color: var(--primary_color);
    font-size: 14px;
    margin-top: 10px;
    padding: 3px 20px;
  }
}
