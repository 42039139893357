.container_home {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("../../../assets/bg-home.webp");
  background-size: cover;
}

.container_title_logo_buttons {
  position: absolute;
  display: block;
  width: 95%;
  max-width: 100%;
  padding-top: 5%;
  padding-left: 5%;
}

.container_logo {
  margin-block: 1%;
}

/* Buttons */

.container_buttons {
  display: flex;
  margin-block: 1%;
  gap: 10px;
}
