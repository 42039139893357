.process_container {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;

  .title_table {
    margin-top: 15px;
  }

  .controls {
    display: flex;
    align-items: center;
    padding-right: 15px;
    width: max-content;
    gap: 10px;

    .toggle {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 15px;
      width: 180px;
      height: 100%;
      font-weight: 600;

      span {
        display: flex;
        align-items: center;
        cursor: default;
      }

      button.btn_add {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        font-size: 16px;
        font-weight: 600;
        color: var(--primary_color);
        border: none;
        background: none;
        cursor: pointer;
      }
    }
  }

  .content {
    h2 {
      font-size: 20px;
      font-weight: 500;
      margin: 0 0 20px 105px;
      color: var(--primary_color);
    }

    table {
      max-width: 950px;
      text-align: center;
      border-spacing: 10px 7px;
      table-layout: fixed;

      p {
        text-align: center;
        color: var(--primary_color);
        margin: 0 auto;
      }

      tr {
        background-color: rgba(244, 244, 244, 1);

        &:hover {
          background: rgba(234, 229, 243, 1);
        }

        th {
          border-collapse: collapse;
          border-spacing: 0;
          font-size: 16px;
          font-weight: 400;
          color: var(--primary_color);
          padding: 0.5rem;
        }
        td:nth-child(4) {
          display: flex;
          justify-content: center;
        }
        td {
          font-size: 16px;
          font-weight: 300;
          color: var(--color-paragraph);
          margin: 0 auto;
          padding: 0.5rem 10px;

          label {
            display: flex;
            align-items: center;
            padding: 20px;
          }
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 0.5rem;
            font-size: 18px;
            font-weight: 600;
            color: var(--primary_color);
            border: none;
            background: none;
            cursor: pointer;
          }
        }

        .description {
          max-width: 470px;
          font-size: 16px;
          text-align: center;
        }

        .transmitter {
          padding: 0 20px;

          div {
            height: 40px;
            display: flex;
            align-items: center;
            column-gap: 8px;
            padding: 0 10px;
            border-radius: 23px;
            background-color: white;
            box-shadow: 0px 0.7718629837036133px 6.174903869628906px 0px
              rgba(0, 0, 0, 0.25);

            span {
              font-size: 11px;
              font-weight: 400;
            }

            img {
              border-radius: 50%;
            }
          }
        }
      }

      .active {
        background: rgba(234, 229, 243, 1);
      }
      td:first-child {
        font-weight: 700;
        color: var(--primary_color);
      }
    }
  }
}

.container_table {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 2;
  width: auto;
  color: var(--primary_color);

  .title_table {
    margin-top: 15px;
  }

  h1 {
    margin: 0;
    font-size: 16px;
    color: var(--primary_color);
    font-weight: 500;
    margin: -20px 0px -30px 0px;
  }

  .controls_table {
    display: flex;
    align-items: center;
    padding-right: 15px;
    width: max-content;
    gap: 10px;

    .toggle_table {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 15px;
      width: 180px;
      height: 100%;
      font-weight: 600;

      span {
        display: flex;
        align-items: center;
        cursor: default;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;

    h2 {
      font-size: 20px;
      font-weight: 500;
      margin: 0 0 20px 105px;
      color: var(--primary_color);
    }
    table {
      max-width: 950px;
      text-align: center;
      border-spacing: 10px 7px;
      table-layout: fixed;
      margin-bottom: 20px;
      p {
        text-align: center;
        color: var(--primary_color);
        margin: 0 auto;
      }
      tr {
        background-color: rgba(244, 244, 244, 1);

        &:hover {
          background: rgba(234, 229, 243, 1);
        }

        th {
          border-collapse: collapse;
          border-spacing: 0;
          font-size: 14px;
          font-weight: 400;
          color: var(--primary_color);
          padding: 2px 10px;
          width: 350px;
        }

        td {
          font-size: 14px;
          font-weight: 300;
          color: var(--color-paragraph);
          padding: 15px 0px;
          text-align: center;
          padding-inline: 5px;

          & strong {
            color: var(--primary_color);
          }

          .icon {
            color: var(--color-paragraph);
          }
        }

        .td_description {
          text-align: left;
          overflow-y: auto;

          div {
            max-height: 60px;
          }
        }

        .td_color {
          color: var(--primary_color);
          font-weight: 500;
        }

        .th_id_level_action {
          width: 80px;
        }

        .th_level_action {
          width: 150px;
        }

        .td_icon_description {
          display: flex;
          align-items: center;
          text-align: left;
          width: 100%;
          gap: 10px;

          & .icon_width {
            width: 50px;
            font-size: 25px;
          }

          & span {
            width: 100%;
          }
        }

        .td_icon_edit {
          margin-right: 15px;
        }
      }

      .active {
        background: rgba(234, 229, 243, 1);
      }
    }
  }
}
