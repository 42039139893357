.container_principal {
  display: flex;
  justify-content: center;
}

.close_modal {
  position: absolute;
  right: 6px;
  top: 6px;
  background: none;
  border: none;
  color: var(--primary_color);
  cursor: pointer;
}

.close_modal:active {
  opacity: 0.5;
}

.modal_content {
  display: flex;
  gap: 30px;
  padding: 30px 60px;
}
