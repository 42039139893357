.toggle_container {
  position: relative;
  width: 100px;
  height: 30px;
  padding: 2px;
  border-radius: 40px;
  background-color: white;
  box-shadow: 0px 3.968989849090576px 19.84494400024414px 0px rgba(0, 0, 0, 0.4);

  label {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 600;
    color: var(--primary_color);
    background-color: var(--primary_color);

    span {
      display: block;
      position: absolute;
      right: 20px;
      transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .active {
      color: var(--secondary_color);
      left: 15%;
    }
  }

  input {
    display: none;
    &:checked + label {
      margin-left: auto;
      background-color: var(--secondary_color);
    }
  }
}
