.container_principal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.paragrap_gray {
  color: var(--light-gray-color);
}

.section_titles {
  margin-top: 15px;
  width: 90%;
}
.icon_help {
  background-color: "red";
  margin-bottom: -15px;
  width: 13%;
}

.container_action_selection {
  width: 70%;
  line-height: 18px;
}

.container_icons {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .icon:active {
    opacity: 0.5;
  }
}

.span_title_icon {
  font-size: 12px;
  color: var(--primary_color);
  font-weight: 500;
}

/* Section box */

.section_box {
  display: flex;
  flex-direction: column;
  width: 90%;
  border: 1px solid var(--light-gray-color);
  border-radius: 5px;
  padding: 15px 15px 10px 15px;
  margin-top: 15px;
  background-color: #fff;
  z-index: 3;
}

.container_add_task_text {
  margin-bottom: -15px;
}

.container_content {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  padding-block: 3px;
  background-color: #f4f4f4;
}

li {
  display: flex;
  align-items: center;
  font-size: 14px;
  list-style: none;
  color: var(--prargraph-color);
  padding-left: 10px;
  height: 100%;
}

.section_buttons {
  display: flex;
  width: 100%;
  bottom: 0;
  padding-inline: 30%;
  margin-block: 20px;
  gap: 20px;
  justify-content: center;

  .link {
    height: 100%;
    width: 100%;
  }
}
