.goal_container {
  height: 300px;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  button.btn_add {
    width: 100%;
    height: 57px;
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    font-size: 17px;
    font-weight: 600;
    color: var(--primary_color);
    margin-bottom: 0.5rem;
    border: none;
    background: none;
    cursor: pointer;
  }
  div.header {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: clamp(0.875rem, 0.7143rem + 0.7143vw, 1.25rem);
      font-weight: 800;
      color: var(--primary_color);
      margin: 0 auto 0 auto;
    }

    button {
      position: absolute;
      right: 5px;
      top: -5px;
      display: flex;
      align-items: center;
      column-gap: 0.1rem;
      font-size: 14px;
      color: var(--placeholder-color);
      border: none;
      background-color: white;
      cursor: pointer;
    }
  }
  div.content {
    min-height: 252px;
    position: relative;
    display: flex;
    justify-content: center;
    column-gap: 1rem;
    margin: 0 auto;

    div.content_left,
    div.content_right {
      min-width: 300px;
      display: flex;
      flex-direction: column;
    }

    input {
      height: 57px;
    }

    input,
    textarea {
      min-width: 250px;
      font-size: clamp(0.875rem, 0.8482rem + 0.119vw, 0.9375rem);
      border: 1px solid var(--placeholder-color);
      border-radius: 0.3rem;
      padding-left: 0.5rem;

      &::placeholder {
        color: var(--placeholder-color);
      }

      &:focus {
        outline-color: var(--primary_color);
      }
    }

    .content_left {
      position: relative;
      flex-direction: column;
      row-gap: 0.5rem;

      textarea {
        resize: none;
        min-height: 125px;
        form-sizing: content;
        font-family: var(--font-name);
        padding: 1rem 0.5rem;
      }
    }

    .content_right {
      flex-direction: column;

      .button_disabble {
        opacity: 0.5;
        pointer-events: none;
      }

      ul.content_search {
        max-width: 300px;
        max-height: 120px;
        margin-top: 0.5rem;
        padding: 0;
        background: rgba(255, 255, 255, 1);
        z-index: 2;
        overflow-y: scroll;

        li {
          font-size: 16px;
          list-style: none;
          padding: 0.5rem 0;
          border-bottom: 1px solid var(--placeholder-color);
          cursor: pointer;
          button {
            height: 30px;
          }
          height: max-content;
        }
      }

      ul.content_checkbox {
        width: 100%;
        min-width: 297px;
        min-height: 200px;
        border: 1px solid var(--placeholder-color);
        border-radius: 0.3rem;
        padding: 0.5rem;
        overflow-y: scroll;
        margin: 0;
      }

      li {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        margin: 0.4rem 0;
        list-style: none;
        font-size: 14px;
        color: var(--gray-color);

        input[type="checkbox"] {
          accent-color: var(--primary_color);
          min-width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }
  }

  div.content_buttons {
    min-width: 530px;
    display: flex;
    justify-content: center;
    column-gap: 0.5rem;
    margin: 2rem auto 0;
  }
}
