.sidebar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100%);
  box-shadow: 0px 0px 40px 0px #00000066;
  background-color: white;
  overflow-y: auto;
  color: var(--primary_color);
  padding: 48px 15px 48px 20px;
  

  nav {
    align-self: flex-start;
    display: grid;
    gap: 10px;

    div {
      width: 100%;
      display: flex;
      gap: 14px;
      align-items: center;
      border-radius: 22px;
      color: var(--primary_color);
      font-weight: 600;
      font-size: 18px;
      text-decoration: none;
      transition: all 0.5s ease;
      cursor: pointer;
    }

    .active {
      background: var(--secondary_color_opacity);
    }
    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    div:active {
      opacity: 0.5;
    }

    .container_select_option {
      display: block;
      margin: 0;
      padding: 0;
      padding-left: 10%;
      margin-top: 3px;

      article {
        margin-top: 10px;
      }
    }
  }

  button {
    align-self: flex-start;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 14px;
    background: none;
    border: none;
    font-weight: 600;
    font-size: 18px;
    color: var(--primary_color);
    cursor: pointer;
  }
}
