.container_principal {
  width: 100%;

  .container_chart {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .text_y_axis {
      display: flex;
      justify-content: center;
      align-items: center;
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
      width: 300px;
      margin-left: -140px;
      margin-right: -20px;
      margin-bottom: 40px;
    }

    .box_chart {
      width: 100%;
      text-align: center;
      margin-left: -150px;

      .text_x_axis {
        margin-left: 50px;
      }
    }
  }

  .container_legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: max-content;
    width: 100%;
    margin-left: 10px;
    background-color: rgba(0, 222, 151, 0.05);

    .container_inter_legend {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 10px;
      justify-content: center;
      margin-top: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid var(--placeholder-color);

      .container_circle_point {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 10px;

        .box {
          width: 15px;
          height: 15px;
          border-radius: 100%;
        }

        span {
          text-align: start;
          width: 80%;
        }
      }
    }

    .title_legend {
      margin-top: -5px;
      width: 100%;
      text-align: center;
    }
  }
}
