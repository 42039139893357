.payments_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;

  .container_title_toggle {
    display: flex;
    width: 100%;
  }

  h2 {
    width: 85%;
    margin: 0;
    padding: 5px 0px;
    font-size: 20px;
    padding-left: 3%;
    color: var(--primary_color);
    font-weight: 300;
    word-wrap: break-word;
  }

  .content {
    display: flex;
    justify-content: center;

    h2 {
      font-size: 20px;
      font-weight: 500;
      margin: 0 0 20px 105px;
      color: var(--primary_color);
    }
    table {
      max-width: 950px;
      text-align: center;
      border-spacing: 10px 7px;
      table-layout: fixed;
      p {
        text-align: center;
        color: var(--primary_color);
        margin: 0 auto;
      }
      tr {
        background-color: rgba(244, 244, 244, 1);

        &:hover {
          background: rgba(234, 229, 243, 1);
        }

        th {
          border-collapse: collapse;
          border-spacing: 0;
          font-size: 14px;
          font-weight: 400;
          color: var(--primary_color);
          padding: 2px 10px;
          width: 250px;
        }

        td {
          font-size: 14px;
          font-weight: 300;
          color: var(--color-paragraph);
          padding: 15px 0px;
          text-align: center;
          padding-inline: 5px;
        }

        .container_status_pencil {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 10px;

          button {
            border: none;
            background-color: transparent;
            outline: none;
          }
        }

        .span_pencil {
          width: 80%;
        }

        select {
          width: 60%;
          margin-inline: 10%;
        }

        .icon_pencil {
          font-size: 18px;
        }

        .icon_pencil:hover {
          color: var(--primary_color);
        }

        .icon_pencil:active {
          opacity: 0.5;
        }

        .td_color {
          color: var(--primary_color);
          font-weight: 500;
        }

        .td_icon_description {
          display: flex;
          align-items: center;
          text-align: left;
          width: 100%;
          gap: 10px;

          & .icon_width {
            width: 50px;
            font-size: 30px;
          }

          & span {
            width: 100%;
          }
        }
      }

      .active {
        background: rgba(234, 229, 243, 1);
      }
    }
  }

  .content_price_table {
    width: 100%;

    .container_th {
      width: 100%;

      strong {
        font-size: 18px;
        font-weight: 800;
      }
    }
  }
}
