.container_confirmation_message {
    padding: 1rem;

    div.content_text {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        column-gap:1rem ;

        p {
            max-width: 300px;
            font-size: 20px;
            font-weight: 500;
            color: var(--primary_color);
        }
    }
    div.content_buttons{
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1rem;
        margin-top: 1rem;
    }
}