.new_goal_container {
  position: relative;

  div.header {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    h2 {
      font-size: clamp(0.875rem, 0.7143rem + 0.7143vw, 1.25rem);
      font-weight: 800;
      color: var(--primary_color);
      margin: 0 auto 1rem auto;
    }

    button {
      position: absolute;
      right: 0;
      top: -20px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: var(--placeholder-color);
      border: none;
      background-color: white;
      cursor: pointer;

      &:active {
        opacity: 0.5;
      }
    }
  }

  div.content {
    display: flex;
    justify-content: space-around;
    column-gap: 0.5rem;

    div.content_left,
    div.content_right {
      width: 50%;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      
      label {
        width: 100%;
        height: 10px;
        font-size: 12px;
        color: rgba(96, 98, 99, 1);
        margin: 0;
      }
    }

    
    // select {
    //   height: 57px;
    //   font-size: clamp(0.875rem, 0.8482rem + 0.119vw, 0.9375rem);
    //   border: 1px solid var(--placeholder-color);
    //   border-radius: 0.3rem;
    //   padding-inline: 10px;

    //   &::placeholder {
    //     color: var(--placeholder-color);
    //   }

    //   &:focus {
    //     outline-color: var(--primary_color);
    //   }
    // }

    .field_file {
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--placeholder-color);
      border-radius: 0.3rem;

      input[type="file"] {
        height: unset;
        border: none;

        &::file-selector-button {
          padding: 0.3rem;
          background-color: var(--primary_color);
          border: none;
          color: white;
          border-radius: 5px;
        }

        &::file-selector-button:hover {
          background-color: var(--secondary_color);
          cursor: pointer;
        }
      }
    }

    .container_toggle {
      display: flex;
      align-items: center;
      height: 57px;
    }

    select {
      font-family: var(--font-name);
      color: var(--placeholder-color);
    }

    div.content_left {
      min-width: 300px;

      label {
        width: 100%;
        height: 10px;
        font-size: 12px;
        color: rgba(96, 98, 99, 1);
        margin: 0;
      }

      // select {
      //   font-weight: 300;
      //   color: var(--primary_color);
      //   appearance: none;
      //   background-image: url("../../../../public/arrow.svg");
      //   background-size: 20px 12px;
      //   background-position: calc(100% - 20px);
      //   background-repeat: no-repeat;
      //   padding-right: 20px;
      // }
    }

    div.content_right {
      min-width: 300px;

      ul {
        max-height: 209px;
        min-height: 56px;
        border: 1px solid var(--placeholder-color);
        border-radius: 0.3rem;
        padding: 0 0.5rem;
        margin: 0;
        overflow-y: scroll;

        button.btn_add {
          width: 100%;
          height: fit-content;
          display: flex;
          align-items: center;
          column-gap: 0.5rem;
          font-size: 18px;
          font-weight: 600;
          color: var(--primary_color);
          border: none;
          background: none;
          cursor: pointer;
          padding-bottom: 1rem;
        }

        li {
          display: flex;
          align-items: center;
          column-gap: 0.5rem;
          list-style: none;
          font-size: 16px;
          color: var(--gray-color);
          margin: 0.4rem 0;
          padding: 0.5rem 0;
          border-bottom: 1px solid var(--placeholder-color);
          cursor: pointer;
          height: max-content;

          span {
            width: 90%;
          }

          input[type="checkbox"] {
            accent-color: var(--primary_color);
            min-width: 24px;
            height: 24px;
            cursor: pointer;
          }
        }
      }
    }
  }

  div.content_goals {
    h3 {
      font-size: 16px;
      font-weight: 600;
      color: var(--primary_color);
      text-align: center;
      margin: 0;
    }

    width: 100%;
    min-width: 240px;
    min-height: 200px;
    max-height: 300px;
    border: 1px solid var(--placeholder-color);
    border-radius: 0.3rem;
    overflow-y: auto;
    padding: 0.5rem;
    margin: 0;

    ul {
      overflow-y: auto;
      padding: 0;
      margin: 0;
      li {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        list-style: none;
        font-size: 14px;
        margin: 0.4rem 0;
        cursor: pointer;
        p {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin: 0;
          color: var(--gray-color);
        }

        input[type="checkbox"] {
          accent-color: var(--primary_color);
          min-width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }
  }

  div.content_buttons {
    display: flex;
    justify-content: center;
    column-gap: 0.5rem;
    margin-top: 2rem;
  }
}
