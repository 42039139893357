.container_button_float {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 0;
  padding: 10px 10px 0px 0px;

  button {
    margin: 5px 5px 0px 0px;
    padding: 0;
    background-color: transparent;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
    width: max-content;

    &:active {
      opacity: 0.5;
    }

    .icon {
      position: relative;
      z-index: 10;
      border-radius: 100%;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
      font-size: 30px;
      background-color: #fff;
    }

    ////////////////////////

    ////////////////////////////////

    .icon_shadown_good {
      &:hover {
        box-shadow: 0px 0px 5px 2px var(--secondary_color);
      }
    }

    .icon_shadown_warning {
      &:hover {
        box-shadow: 0px 0px 5px 2px #ffc21d;
      }
    }

    .icon_shadown_bad {
      &:hover {
        box-shadow: 0px 0px 5px 2px #ee3537;
      }
    }
  }

  button::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: rgba(47, 0, 132, 0.8);
    border-radius: 50%;
    animation: pulsate 3s infinite;
  }

  @keyframes pulsate {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }
}
