// FloatingButton.module.scss

.floatingButtonContainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 5;
}

.floatingButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background-color: var(--placeholder-color); // Color de WhatsApp
  border-radius: 50%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  color: #fff;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.3s, border-radius 0.3s, width 0.3s;
}

.hovered {
  width: auto;
  border-radius: 5px;
  background-color: var(--primary_color); // Color oscuro de WhatsApp para hover
}

.icon {
  position: absolute;
  font-size: 24px;
  color: white; // Asegura que el ícono sea visible
  transition: transform 0.3s;
}

.floatingButton:hover .icon {
  transform: rotate(360deg);
}

.tooltipText {
  font-size: 16px;
  color: #fff;
  white-space: nowrap;
  transform: translateX(100%);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  margin-inline: 10px;
  padding-inline: 10px;

  button {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 16px;

    &:active {
      opacity: 0.5;
    }
  }
}

.visible {
  transform: translateX(0);
  opacity: 1;
}
