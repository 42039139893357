.container_principal {
  padding-inline: 10px;
  width: 700px;
  overflow-y: auto;

  .paragrahp_download {
    position: absolute;
    width: 88%;
    gap: 5px;
    margin-top: -14px;
    padding: 0;
    font-size: 14px;
    z-index: 200;
    color: var(--light-gray-color);

    .div {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 5px;
      padding: 0;
      right: 0;
      cursor: pointer;
    }

    .div:active {
      opacity: 0.5;
    }
  }

  .section_dashed {
    display: block;
    padding-block: 5px;
    margin-bottom: 5px;
    border-bottom: 1px dashed var(--placeholder-color);
  }

  .container_flex {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .container_gap_dates {
    gap: 30px;
  }

  .container_gant_chart {
    margin: 15px 0px 60px 0px;
    div {
      margin-top: 5px;
    }
  }

  .section_btn {
    display: flex;
    align-items: center;
    width: 100%;
    margin-block: 10px;
    padding-inline: 15%;
    display: flex;
    justify-content: space-between;
  }
}
