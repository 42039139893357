.password_reset_container {
    max-width: 380px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    img {
        width: 187px;
        height: 187px;
        margin: 0 auto;
    }

    label {
        max-width: 380px;
        font-size: clamp(0.8125rem, 0.75rem + 0.2778vw, 1rem);
        font-weight: 300;
        text-align: center;
        color: var(--color-paragraph);
        margin: 0;
    }
    input{
        width: 100%;
    }
    button{
        margin-top: 20px;
    }
}