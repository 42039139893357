.payment_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  column-gap: 20px;

  .decorator {
    width: 700px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  article {
    display: grid;
    grid-template-columns: repeat(2, 330px);
    background: rgba(255, 255, 255, 0.658);
    gap: 30px;
    padding: 30px;
    border-radius: 4px;

    header {
      display: flex;
      flex-direction: column;
      span.recommendation {
        background-color: var(--secondary_color);
        font-size: 20px;
        font-weight: 800;
        color: white;
        width: 100%;
        display: inline-block;
        text-align: center;
        padding-block: 6px;
      }
      img {
        margin: 40px auto;
      }
      h1 {
        font-size: 32px;
        color: var(--primary_color);
        font-weight: 600;
        text-align: center;
        margin: 0;
      }
      p {
        font-size: 28px;
        color: var(--primary_color);
        font-weight: 300;
        text-align: center;
        margin: 0;
        span {
          font-size: 20px;
        }
      }
    }
    ul {
      list-style-type: none;
      padding: 0;
      li {
        padding-left: 30px;
        background-image: url("../../../assets/icons/check.svg");
        background-repeat: no-repeat;
        background-position: left center;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }
  .items_center {
    place-items: center;
  }
  aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h2 {
      font-size: 40px;
      color: var(--primary_color);
      font-weight: 600;
      margin: 0;
    }
    p {
      font-size: 20px;
      font-weight: 400;
      color: var(--color-paragraph);
      margin: 0;
    }
    form {
      margin-top: 10px;
      padding: 0;
      list-style-type: none;
      text-align: left;
      width: 80%;
      div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 20px;
        gap: 10px;
        border: solid 1px var(--light-gray-color);
        margin-bottom: 8px;
        color: var(--primary_color);
        font-weight: 400;
        font-size: 20px;

        span {
          width: 60%;
        }

        input {
          width: 40%;
          color: var(--color-paragraph);
          font-size: 24px;
          padding: 0;
          margin: 0;
          border: none;
          text-align: start;
        }
      }

      .container_total_price {
        display: block;
        text-align: center;
        h2 {
          font-size: 16px;
        }

        input {
          width: 100%;
          text-align: center;
          font-size: 30px;
          font-weight: 600;
          color: var(--secondary_color);
        }
      }
    }
    a {
      color: var(--secondary_color);
    }
    a:hover {
      background: none;
    }
  }
}
