/* src/MultiSelect.scss */

.multi_select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  font-family: var(--font-name);
  font-size: 16px;
  color: var(--light-gray-color);
  font-weight: 300;
}

.select_box {
  width: max-content;
  // max-width: 200px;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
}

.select_box:hover {
  border-color: #888;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #888;
  transition: transform 0.3s;
}

.arrow.open {
  transform: rotate(180deg);
}

.options_container {
  position: fixed;
  flex-direction: column;
  border-radius: 5px;
  width: max-content;
  max-width: 500px;
  height: max-content;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid var(--placeholder-color);
  background-color: #fff;
  z-index: 10;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  top: 140px;
  
  .option {
    padding-block: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .option:hover {
    background-color: var(--input-backgrond-color);
  }

  .option input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid var(--placeholder-color);
    border-radius: 4px;
    display: inline-block;
    margin-right: 10px;
  }

  .option input[type="checkbox"]:checked + .checkmark {
    background-color: var(--secondary_color);
    border-color: var(--secondary_color);
  }
}
