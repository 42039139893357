.container_modal {
  position: relative;
  display: flex;
  min-width: 500px;
  max-width: 700px;
  flex-direction: column;
  align-items: center;
  font-size: 16px;

  .container_icons {
    position: absolute;
    right: 0;
    margin: -13px 20px 0px 0px;
    color: var(--light-gray-color);

    &:active {
      opacity: 0.5;
    }
  }

  .container_title_date {
    width: 100%;
    margin-top: 20px;
    p {
      display: flex;
      gap: 5px;
      margin: 0;
      padding: 0;
      color: var(--light-gray-color);
    }

    .paragrap_date {
      margin: 2px 0px 10px 35px;

      span {
        font-size: 15px;
      }
    }
  }

  .container_description {
    width: 100%;
    padding-left: 32px;

    p {
      padding: 0;
      margin: 0;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      font-size: 16px;
      gap: 5px;
      color: var(--light-gray-color);
      font-weight: 300;

      span {
        color: var(--placeholder-color);

        font-weight: 500;
      }
    }

    .title_description {
      margin: 10px 0px 0px 0px;
    }

    .Paragraph_description {
      background-color: var(--text-area-color);
      padding: 5px 10px;
      border-radius: 5px;
    }
  }

  .container_person {
    ul {
      margin: 0;
      li {
        color: var(--light-gray-color);
        font-weight: 300;
        list-style-type: none;
      }
    }
  }

  .container_process_goal {
    display: flex;
    width: 100%;
    padding-inline: 35px;
    margin-top: 15px;

    section {
      align-items: flex-start;
      flex-direction: column;
      width: 50%;

      span {
        margin-top: -10px;
        color: var(--light-gray-color);
        font-weight: 300;
      }
    }
  }

  .container_link_meeting {
    width: 100%;
    a {
      position: relative;
      text-align: start;
      left: 0;
      margin-left: 35px;
      color: var(--primary_color);
      margin-top: 5px;
      margin-bottom: -5px;
      cursor: default;

      &:hover {
        background-color: transparent;
        color: var(--secondary_color);
      }
    }
  }

  .section_buttons {
    display: flex;
    width: 500px;
    margin-top: 20px;
    gap: 10px;
    justify-content: center;
  }
}
