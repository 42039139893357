.principal_nav_bar {
  display: flex;
  width: max-content;
  min-width: 98%;
  font-family: var(--font-name);
  font-size: 16px;
  color: var(--light-gray-color);
  font-weight: 300;

  align-items: center;
  padding: 10px;
  box-shadow: 0px 0px 20.1px 0px rgba(0, 0, 0, 0.25);
  margin-inline: 10px;

  .nav_bar_container {
    display: flex;
    gap: 5px;
    margin-left: 20px;

    input,
    button {
      height: max-content;
      height: 40px;
      width: max-content;
      padding: 10px 15px;
    }

    button {
      border-radius: 5px;
      border: none;
      font-weight: 600;

      &:active{
        background-color: var(--secondary_color);
      }
    }
  }

  .selectors {
    display: flex;
  }

  .arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #888;
    transition: transform 0.3s;
  }

  .arrow.open {
    transform: rotate(180deg);
  }

  .select_dates {
    display: flex;
    gap: 5px;
    align-items: center;
    max-width: 150px;
    width: max-content;
    padding: 10px;

    .container_text_arrow {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    .container_date_keywords_butn {
      width: max-content;
      height: max-content;
      color: var(--primary_color);
      font-weight: 400;
      padding: 5px 10px;
      border-radius: 3px;
      background-color: transparent;
      cursor: default;
      border: 1px solid var(--secondary_color);

      &:active {
        opacity: 0.5;
      }
    }

    .options_container {
      display: flex;
      position: absolute;
      border-radius: 5px;
      width: max-content;
      max-width: 500px;
      height: max-content;
      border: 1px solid var(--placeholder-color);
      background-color: #fff;
      z-index: 10;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      padding: 5px 10px 10px 10px;
      margin-top: 150px;
      margin-left: -150px;
      gap: 10px;

      .paragraph {
        color: var(--primary_color);
      }

      .input_keyboard {
        border: none;
        width: 275px;
        height: max-content;
        padding-inline: 2px;
        padding-bottom: 3px;
        margin-block: 10px;
        border-bottom: 1px solid var(--placeholder-color);
        border-radius: 0;

        &:focus {
          outline: none;
          border-bottom: 1px solid var(--primary_color);
        }
      }
    }

    .options_container_keyboard {
      margin-left: -90px;
      margin-top: 120px;
      width: 300px;
    }
  }
}
