.toggle_container {
    position: relative;
    width: 180px;
    height: 60px;
    padding: 5px;
    border-radius: 40px;
    background-color: white;
    box-shadow: 0px 3.968989849090576px 19.84494400024414px 0px rgba(0, 0, 0, 0.4);

    label {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 50%;
        font-size: 16px;
        font-weight: 600;
        color: var(--primary_color);
        background-color: var(--primary_color);

        span{
            display: block;
            position: absolute;
            right: 30px;
            transition: transform 0.25s ease;
        }
        .active{
            color: var(--secondary_color);
            left: 15%;
        }
    }

    input {
        display: none;
        &:checked+label {
            margin-left: auto;
            background-color: var(--secondary_color);
            
        }
    }
}