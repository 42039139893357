.container_principal_pir_chart {
  display: flex;
  height: max-content;
  gap: 15px;

  .container_legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: max-content;
    width: 100%;

    .title {
      text-align: start;
    }

    .container_circle_point {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 10px;

      .box {
        width: 15px;
        height: 15px;
        border-radius: 100%;
      }

      span {
        text-align: start;
        width: 90%;
      }
    }
  }
}
