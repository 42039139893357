a.link {
  display: flex;
  gap: 14px;
  align-items: center;
  border-radius: 22px;
  color: var(--primary_color);
  font-weight: 600;
  font-size: 18px;
  text-decoration: none;
  padding: 6px 0px;
  transition: all 0.5s ease;
}
a:hover {
  background: var(--secondary_color_opacity);
}
.active {
  background: var(--secondary_color_opacity);
}
a.disabled {
  opacity: 0.5;
  pointer-events: none;
}
