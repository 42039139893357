.container_principal {
  width: 100%;
  padding: 10px 15px;

  .title {
    line-height: 35px;
    margin-bottom: 15px;
  }

  .container_link {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    a {
      margin: 15px -20px 0px 0px;
      text-align: end;
      width: 100%;
      font-size: 16px;
      color: var(--primary_color);
      text-decoration: none;

      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }
  }
}
