.container_gauge {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title_count {
    margin: -15px 0px -10px 0px;
  }

  .container_chart {
    height: 140px;
  }

  .container_legend {
    width: 250px;
    display: flex;
    position: relative;
    justify-content: space-around;
    align-items: center;
    gap: 90px;
    margin-top: -28px;
    border-top: 4px solid var(--placeholder-color);
    padding-top: 3px;

    .container_box_text {
      width: max-content;
      display: flex;
      align-items: center;
      gap: 5px;

      .box {
        height: 20px;
        width: 20px;
        background-color: #fff;
      }
    }
  }

  .paragraph_description {
    text-align: justify;
    margin-top: 10px;
  }
}
