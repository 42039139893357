.container_accordion {
  .select_box {
    width: 100%;
    padding: 8px 10px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary_color);
    color: var(--text-area-color);
    font-size: 18px;
    font-weight: 700;
    padding-left: 30px;
  }

  .arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--text-area-color);
    transition: transform 0.3s;
  }

  .arrow.open {
    transform: rotate(180deg);
  }
}
