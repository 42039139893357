.container_link {
  color: var(--light-gray-color);
  border-bottom: 1px solid var(--light-gray-color);
  cursor: pointer;
  font-weight: 300;
  text-align: end;
}

.container_link:active {
  opacity: 0.5;
}
