.speech_tree_container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 3rem;
  overflow-y: scroll;
  z-index: 3;

  .title {
    color: var(--primary_color);
    font-weight: 700;
    font-size: 22px;
    text-align: start;
  }

  .container_content {
    width: 100%;

    hr {
      height: 1px;
      width: 100%;
      margin-block: 10px;
    }
    h2 {
      font-size: 25px;
      font-weight: 800;
      margin-top: 0;
      color: var(--primary_color);
    }

    p {
      display: flex;
      align-items: start;
      column-gap: 0.5rem;
      font-size: 16px;
      font-weight: 700;
      color: var(--color-paragraph);
      margin: 0.5rem 0;

      span {
        color: var(--placeholder-color);
        font-size: 16px;
        font-weight: 500;
      }
    }
    h3 {
      font-size: 16px;
      font-weight: 500;
      color: var(--color-paragraph);
      margin: 0;
      padding: 0;
    }

    .content_collaborators {
      width: 100%;
      display: flex;
      gap: 10px;
      align-items: center;
      padding-block: 2px;

      input {
        margin-top: -10px;
        width: 250px;
        color: var(--color-paragraph);
      }
    }
    .actions {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 20px;

      button {
        font-size: 16px;
        font-weight: 600;
        color: var(--primary_color);
        border: none;
        border-radius: 4.5px;

        height: max-content;
        padding: 2px 20px;
        background-color: var(--secondary_color);
        cursor: pointer;

        &:active {
          opacity: 0.5;
        }
      }
      div {
        display: flex;
        align-items: flex-end;
        font-size: 16px;
        color: var(--color-paragraph);
        cursor: default;
      }

      div:active {
        opacity: 0.5;
      }
    }

    .container_collaborators_block {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      margin-top: 5px;

      .container_generated_minutes {
        display: flex;
        align-items: center;
        gap: 5px;
        margin: 0;
        padding: 0;

        .paragraph_list_minutes {
          padding: 0;
          margin: 0;
          border-bottom: 1px solid transparent;

          &:hover {
            cursor: default;
            color: var(--secondary_color);
            border-bottom: 1px solid var(--secondary_color);
          }

          &:active {
            opacity: 0.5;
          }
        }

        .icon_checkmark {
          color: var(--secondary_color);
        }
      }
    }
  }

  .action_step {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-block: 15px;
    padding-inline: 20%;
    button {
      width: 40%;
    }
    a.link {
      width: 30%;
      font-size: 16px;
      text-decoration: none;
    }
  }
}
