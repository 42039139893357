.container_principal_funnel {
  width: 100%;
  display: block;

  .container_name_bar {
    display: flex;
    width: 100%;
    align-items: center;
    height: 40px;
    gap: 10px;

    .container_name {
      display: flex;
      justify-content: flex-end;
      width: 25%;
      text-align: end;
    }

    .container_bar {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 70%;

      .bar_funnel {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 30px;
        height: 35px;
        background-color: var(--primary_color);
        color: var(--text-area-color);
        span {
          font-weight: 700;
          font-size: 18px;
        }
      }
    }
  }
}
