.container_principal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px 20px 15px;

  .paragraph {
    margin-block: 15px;
  }

  .container_form {
    display: block;

    .container_date_inputs {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 10px;

      .container_box_date {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        height: 55px;
        padding-inline: 15px;
        min-width: 55px;
        font-weight: 800;
      }

      .container_label_input {
        display: block;

        .container_label {
          margin-left: 10px;
        }

        input {
          min-width: 300px;
          margin-right: 30px;
        }
      }
    }

    .container_buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-block: 30px;
      gap: 15px;
    }
  }
}
