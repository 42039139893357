.container_principal {
  position: relative;
  z-index: 100;
}

.paragraph {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 20px;
  margin-left: 5%;
  strong {
    color: var(--primary_color);
    font-size: 25px;
  }
}

.container_section_graph {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5%;
}

.container_title_graph {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.box_blue {
  background-color: var(--primary_color);
  height: 20px;
  width: 20px;
}

.box_green {
  background-color: var(--secondary_color);
  height: 20px;
  width: 20px;
}

section {
  display: flex;
  align-items: center;
  gap: 5px;
}

.container_principal_circle {
  text-align: center;
}

h1 {
  font-weight: 400;
  font-size: 20px;
  padding: 0;
  margin: 0;
  margin-bottom: 10%;
}
