.principal_nav_bar {
  display: flex;
  width: max-content;
  min-width: 98%;
  font-family: var(--font-name);
  font-size: 16px;
  color: var(--light-gray-color);
  font-weight: 300;

  align-items: center;
  padding: 2px 10px;
  box-shadow: 0px 0px 20.1px 0px rgba(0, 0, 0, 0.25);
  margin-inline: 10px;

  .nav_bar_container {
    display: flex;
    gap: 5px;
    margin-left: 20px;

    input,
    button {
      height: max-content;
      height: 40px;
      width: max-content;
      padding: 10px 15px;
    }

    button {
      border-radius: 5px;
      border: none;
      font-weight: 600;

      &:active {
        background-color: var(--secondary_color);
      }
    }
  }

  .selectors {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-block: 10px;

    .container_dates {
      border: 1px solid var(--light-gray-color);
      border-radius: 5px;
    }

    label {
      padding: 0;
      margin: 0;
    }

    div {
      display: flex;
      gap: 10px;
    }
  }

  .arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #888;
    transition: transform 0.3s;
  }

  .arrow.open {
    transform: rotate(180deg);
  }

  .select_dates {
    display: flex;
    gap: 5px;
    align-items: center;
    width: max-content;
    padding: 10px;

    div {
      display: block;
      input {
        height: 35px;
        font-size: 14px;
      }
    }
  }

  .options_container_keyboard {
    margin-left: -90px;
    margin-top: 120px;
    width: 300px;
  }
}
