@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --primary_color: #2f0084;
  --secondary_color: #00de97;
  --without_starting_color: #ff7a00;
  --gray-color: #333333;
  --red-color: #ee3537;
  --light-gray-color: #898989;
  --paragraph_color: #606263;
  --placeholder-color: #bebebe;
  --color-paragraph: #545252;
  --input-backgrond-color: #f4f4f4;
  --text-area-color: #f1f1f1;
  --font-name: "Poppins", sans-serif;
  --size-text: 16px;
  --size-input: 14px;
  --size: clamp(0.75rem, 0.5921rem + 0.7018vw, 1.25rem);
  --secondary_color_opacity: #00de974a;
}

html {
  margin: 0px;
  padding: 0px;
  font-size: var(--size);
}

body,
button,
input,
textarea,
select {
  font-family: var(--font-name);
  font-size: var(--size);
  margin: 0px;
  padding: 0px;
}

input,
select,
button {
  height: 57px;
  color: var(--primary_color);
  font-size: 18px;
  border: 1px solid var(--placeholder-color);
}

li {
  height: max-content;
}

textarea {
  min-height: 125px;
  padding: 5px 10px;
  &::placeholder {
    color: var(--placeholder-color);
  }
}

/* Center container */

.fcdt-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Flexbox */

.fcdt-flex-box {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* CSS Titles */

.fcdt-title-primary {
  color: var(--primary_color);
  font-weight: 500;
}

.fcdt-title-secondary {
  color: var(--secondary_color);
  font-weight: 500;
}

/* CSS Paragraph */

.text_paragraph_thin {
  color: var(--gray-color);
}

.text_paragraph_bold {
  color: var(--light-gray-color);
}

/* CSS buttons */

.fcdt_btn_primary {
  width: 100%;
  background-color: var(--primary_color);
  color: #fff;
  border: none;
  font-weight: 600;
  text-align: center;
  padding: 15px;
  cursor: pointer;
}

.fcdt_btn_primary:active {
  opacity: 0.5 !important;
}

.fcdt_btn_secondary {
  background-color: var(--secondary_color);
  color: #fff;
  border: none;
  font-weight: 600;
  text-align: center;
  padding: 15px;
}

.fcdt_btn_secondary:active {
  opacity: 0.5 !important;
}

.fcdt_btn_without_background_primary {
  color: var(--primary_color);
  background-color: #fff;
  border: none;
  font-weight: 600;
  text-align: center;
  padding: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
}

.fcdt_btn_without_background_primary:active {
  opacity: 0.5 !important;
}

.fcdt_btn_without_background_secondary {
  color: var(--primary_color);
  background-color: transparent;
  border: 1px solid var(--secondary_color);
  font-weight: 600;
  text-align: center;
  padding: 15px;
}

.fcdt_btn_without_background_secondary:active {
  opacity: 0.5;
}

.btn_cancel {
  background-color: white;
  color: var(--primary_color);
  box-shadow: 0px 0px 20.1px 0px rgba(0, 0, 0, 0.25);
}

.btn_cancel:active {
  opacity: 0.5 !important;
}

.fcdt-btn_update {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  column-gap: 0.4rem;
  background-color: var(--secondary_color);
  color: var(--primary_color);
}

.fcdt-btn_update:active {
  opacity: 0.5 !important;
}

/* CSS input text */

.fcdt-input-box-password {
  display: flex;
  align-items: center;
  border: 1px solid var(--placeholder-color);
  font-size: 16px;

  border-radius: 3px;
  color: var(--primary_color);

  &:active {
    outline-color: var(--primary_color);
  }

  input {
    border: none;
    outline: none;
    width: 88%;
    font-size: 16px;
    padding-inline: 15px;

    &::placeholder {
      color: var(--placeholder-color);
      font-weight: 300;
    }
  }
  div {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

input[type="checkbox"] {
  accent-color: var(--primary_color);
  min-width: 24px;
  height: 24px;
  cursor: pointer;
}

.fcdt-input {
  border: 1px solid var(--placeholder-color);
  font-size: 16px;
  padding-inline: 15px;
  border-radius: 3px;
  color: var(--primary_color);

  &:focus {
    outline-color: var(--primary_color);
  }

  &::placeholder {
    color: var(--placeholder-color);
    font-weight: 300;
  }
}

p.message_error {
  font-size: 12px;
  color: #bf1650;
  margin: 0;

  &::before {
    display: inline;
    content: "⚠ ";
  }
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* Icons */

.fcdt-icon {
  font-size: 20px;
  color: var(--primary_color);

  &:active {
    opacity: 0.5;
  }
}

.fcdt-icon-gray {
  font-size: 20px;
  color: var(--paragraph_color);

  &:active {
    opacity: 0.5;
  }
}

.fcdt-icon-green {
  font-size: 20px;
  color: var(--secondary_color);

  &:active {
    opacity: 0.5;
  }
}

.fcdt-gray {
  color: var(--color-paragraph);
}

/* Selects */

.fcdt-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;

  color: var(--light-gray-color);
  font-weight: 300;
  border: 1px solid var(--placeholder-color);
  height: 57px;
  font-size: var(--size-text);
  border-radius: 3px;
  padding: 10px 40px 10px 10px;
  background-color: var(--input-backgrond-color);

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='rgba(47, 0, 132, 1)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 30px;

  outline: none;

  &::placeholder {
    color: var(--placeholder-color);
  }

  option {
    font-weight: 300;
  }
}

/* Scroll bar */

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary_color);
  border-radius: 4px;
}

/* Disable component */

.fcdt-disable-component {
  pointer-events: none;
}

/* fcdt Link to */

.fcdt-link-to {
  text-decoration: none;
  color: var(--primary_color);
}

.fcdt-link-to:active {
  color: var(--primary_color);
}

.fcdt-link-to:hover {
  background-color: transparent;
}

.fcdt-link-to_white {
  color: #fff;
  text-decoration: none;

  &:active {
    opacity: 0.5;
  }

  &:hover {
    background-color: transparent;
  }
}

/* Title responsive */

.fcdt-title-responsive {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}
