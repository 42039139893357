.container_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 3%;
  overflow-x: auto;
  width: 100%;

  .container_title_body {
    text-align: center;
    line-height: 22px;
    margin-bottom: 10px;
  }

  .box_graph_shadown {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 430px;
    max-width: 50%;
    padding: 50px 20px 10px 20px;
    background-color: #fff;
    border-radius: 10px;
    z-index: 3;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);

    .title_box {
      width: 70%;
      text-align: center;
      position: absolute;
      top: 10px;
      margin-bottom: 15px;
      line-height: 20px;
    }
  }

  .box_graph_shadown_100 {
    min-width: 980px;
    margin-bottom: 15px;
    min-height: 100px;
    max-height: 250px;
    overflow-y: auto;
  }

  .box_graph_shadown_100_with_padding {
    padding: 10px 20px;
    width: 100%;
  }

  .box_graph_shadown_width_25 {
    min-width: 250px;
  }

  .container_two_chats {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-block: 5px;
  }

  .container_two_chats_not_title {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-block: 20px;
  }

  .container_process_charts {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .section_process {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;

      .container_chart_bar {
        height: 500px;
      }
    }
  }

  .container_goal_charts {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 2;

    .section_gantt {
      width: 100%;
    }
  }

  .container_scroll_event_task {
    overflow-y: auto;
    max-height: 170px;
    width: 100%;
  }

  .container_events_task {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;
    padding-bottom: 5px;
    margin-bottom: 15px;
    margin-top: 5px;
    border-bottom: 1px solid var(--placeholder-color);

    .container_name_meeting {
      width: 25%;
    }

    .container_bar_percentage {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30%;
      text-align: start;
      margin-bottom: -16px;
    }

    .container_gauge {
      width: 40%;

      .paragraph {
        width: 100%;
        text-align: center;
        margin-top: -10px;
      }
    }
  }

  .container_event_task_second {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 90px;
    margin-top: 15px;

    .paragraph {
      width: 100%;
      text-align: center;
      margin-top: -10px;
    }
  }

  .container_legend {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    margin-bottom: 5px;

    .container_box_text {
      width: max-content;
      display: flex;
      align-items: center;
      gap: 5px;

      .box {
        height: 20px;
        width: 20px;
        background-color: #fff;
      }
    }
  }

  .execution_status {
    display: flex;
    gap: 20px;
    margin-top: -10px;
    margin-bottom: 15px;

    .box_text {
      display: flex;
      align-items: center;
      gap: 5px;

      .box {
        height: 18px;
        width: 18px;
        border: 1px solid var(--placeholder-color);
        &:active {
          opacity: 0.5;
        }
      }
    }
  }
}
