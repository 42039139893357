.container_modal_review {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    min-width: 250px;
    max-width: 300px;
    margin-block: 20px;
  }

  button {
    margin-top: 20px;
  }
}
